@import url('https://fonts.googleapis.com/css2?family=Alice&family=Poppins:wght@400;500;600&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

* {
  /* outline: 1px #ff000020 solid; */
}

.ios-safari body {
  min-height: -webkit-fill-available;
}

.ios-safari-standalone body {
  min-height: 100vh;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

* {
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button[type="button"],
button[type="submit"] {
  box-shadow: none;
}

button.big[type="button"],
button.big[type="submit"] {
  min-height: 60px; 
  font-size: 16px;
  font-weight: 600;
  width: 100%;
}

button.select[type="button"],
button.select[type="submit"] {
  background: #F3E3CF;
  color: #4F3E2B;
  font-feature-settings: 'clig' off, 'liga' off;
  min-height: 48px; 
  font-size: 14px;
  font-weight: 500;
  width: 100%;
}

button.select[type="button"]:hover,
button.select[type="submit"]:hover {
  background: #f8e3c8 !important;
  color: #4F3E2B !important;
}


/* font-family: 'Alice', serif; */
/*  */

.fade-enter{
  opacity: 0;
}
.fade-exit{
  opacity: 1;
}
.fade-enter-active{
  opacity: 1;
}
.fade-exit-active{
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active{
  transition: opacity 500ms;
}

.ant-form-item-label {
  font-weight: 500;
}